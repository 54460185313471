/* Global Styles */
body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #000;
    color: #fff;
    overflow-x: hidden;
}

h1, h2, h3 {
    margin: 0;
}

/* Header */
.header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    position: absolute; 
    top: 0;
    left: 0; 
    right: 0; 
    z-index: 2; 
    color: #fff; 
    background-color: rgba(0, 0, 0, 0); 
}

.header h1 {
    font-family: 'supernova-2', display;
    font-size: 40px;
}

.header {
    display: flex;
    justify-content: space-evenly; /* Adjust spacing between buttons */
    align-items: center; 
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0); 
}

.register-btn {
    padding: 12px 25px;
    background-color: rgba(0, 255, 255, 0.2); /* Cyan transparent background */
    color: #fff; /* White text */
    border: 2px solid cyan; /* Cyan border */
    border-radius: 4px; /* Slightly rounded corners */
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease, color 0.3s ease, border-color 0.3s ease;
    cursor: pointer;
}

.register-btn:hover {
    background-color: rgba(0, 255, 255, 0.4); /* Brighter cyan transparent background */
    color: #000; /* Black text for better contrast */
    border-color: #fff; /* Change border to white on hover */
    transform: translateY(-2px); /* Subtle lift effect */
}

.register-btn:active {
    background-color: rgba(0, 255, 255, 0.6); /* Even less transparent for active state */
    transform: translateY(0);
}


/* Hero Section */
.hero-section {
    text-align: center;
    padding: 100px 20px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)),
                url('../public/b.jpg') no-repeat center center;
    background-size: cover;
    color: #fff;
    position: relative;
    height: 500px;
    
}

.hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    z-index: 1;
}

.hero-content {
    position: relative;
    z-index: 2;
}

.hero-content img {
    width: 150px;
    
    
}

.hero-content h1 {
    font-family: 'Bison', sans-serif;
    font-size: 100px;
    margin-top: 20px;
}

.hero-content p {
    font-size: 20px;
    margin-top: 10px;

}

/* About Section */
.section {
    padding: 50px 20px;
    text-align: center;
    margin-top: 150px;
    margin-bottom: 150px;
    margin-box: 100px
}

.about-section h2, .events-section h2 {
    font-size: 30px;
    margin-bottom: 20px;
}

.about-section p {
    font-size: 18px;
    line-height: 1.5;
    size: 70%;
    margin-left: 100px;
    margin-right: 100px;
}

/* Events Section */
.events-section {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 0%!important;
    height: 275px;
    
}

/* Center-aligning the Events Heading */
.eventsheading {
    font-size: 80px; 
    margin-bottom: 70px!important; 
    color: #fff; 
    font-family: 'Bison', sans-serif; 
    
    width: 100%; 
    position: relative; 
}



.event-card {
    width: 250px;
    background-color: #222;
    border-radius: 10px;
    margin: 20px;
    
    
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.event-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    opacity: 0.7;
}

.event-text {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    z-index: 2;
}

.event-card:hover {
    transform: scale(1.05);

    transform: translateY(-10px); /* Lift effect on hover */
}




/* Theme Section */
.theme-section {
    text-align: center;
    padding: 50px;
    background: url('../public/a.jpg') no-repeat center center;
    background-size: cover;
    color: #fff;
    position: relative;

}

.theme-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.theme-content {
    position: relative;
    z-index: 1;
}

.theme-content h2 {
    font-size: 40px;
}

.theme-content h3 {
    font-size: 30px;
}

.event-date {
    font-size: 30px;
    margin-top: 20px;
}

/* Venue Section */
.venue, .sponsors,.speaker {
    text-align: center;
    padding: 50px;
}

.map {
    width: 100%;
    height: 300px;
    border: none;
}

/* Footer */
.footer {
    text-align: center;
    padding: 20px;
    background-color: 'black';
}

/* Date Section */
.date {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 50px 20px;
    background-color: #131314; 
    border-radius: 10px; 
    box-shadow: 
        0 0 10px rgba(0, 255, 255, 1), 
        0 0 30px rgba(0, 255, 255, 0.9), 
        0 0 60px rgba(0, 255, 255, 1), 
        inset 0 0 10px rgba(0, 255, 255, 1), 
        inset 0 0 30px rgba(0, 255, 255, 0.8); 
    margin: auto; 
    margin-bottom: 100px;
    margin-top: 150px;
    
    background-size: cover; 
    max-width: 85%; 
    transition: box-shadow 0.3s ease-in-out; 
}

.date:hover {
    box-shadow: 
        0 0 20px rgba(0, 255, 255, 1), /* Intense cyan outer glow */
        0 0 40px rgba(0, 255, 255, 1), 
        0 0 80px rgba(0, 255, 255, 1), 
        inset 0 0 20px rgba(0, 255, 255, 1), /* Brighter inner glow */
        inset 0 0 50px rgba(0, 255, 255, 0.9); /* Strong inner glow on hover */
}


.date-content {
    padding: 20px;
}

.date h2 {
    font-family: 'Bison', sans-serif; /* Use the same font as the header */
    font-size: 36px; /* Larger font size for emphasis */
    margin-bottom: 10px;
    color: #ffffff; /* Darker text color for contrast */
}

.date h3 {
    font-family: 'Poppins', sans-serif; /* Different font for subtitle */
    font-size: 28px; /* Slightly smaller than the h2 */
    color: #e74c3c; /* Accent color for highlighting the date */
    margin-top: 40px;
}
.countdown-timer {
    font-size: 130px;
    font-weight: bold;
    margin-top: 10px;
    color: #ffffff;
}

.events-section:hover .event-card{
    filter: blur(3px);
    transition: 0.5s;
}

.events-section:hover .event-card:hover{
    filter: none;
    transform: 0.2s;
}
.faq-container {
    width: 80%;
    max-width: 1000px;
    margin: 50px auto;
    background-color: #1a1a1a;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    margin-top:200px;
    margin-bottom:200px;
}
.faq-header {
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff;
    font-weight: bold;
    
}
.faq-item {
    border-bottom: 1px solid #333;
    padding: 15px 0;
}
.faq-item:last-child {
    border-bottom: none;
}
.question {
    font-size: 1.2em;
    color: #00ccff;
    cursor: pointer;
    position: relative;
}
.question::after {
    content: '+';
    position: absolute;
    right: 0;
    font-size: 1.5em;
    color: #00ccff;
}
.answer {
    display: none;
    margin-top: 10px;
    font-size: 1em;
    color: #ccc;
}
.faq-item.active .answer {
    display: block;
}
.faq-item.active .question::after {
    content: '-';
}


.question:hover {
    color: #66ffff;
}
.faq-item.active .question:hover::after {
    color: #ff6666;
}


  canvas {
    width: 100vw;
    height: 100vh;
  }

.grades-section {
    text-align: center;
    margin-top: 0px;
}

.grades-container {
    display: flex;
    justify-content: space-around; 
    margin-top: 0px;
}

.grade-text {
    font-size: 16px;
    color: #ffffff;
}
